<template>
    <div class="container">

        <el-tabs @tab-click="handleAesClick" style="height: 500px;" v-model="activeName">
            <el-tab-pane v-for="item in buttionArr" :key="item.id" :label="item.menuName" :name="item.code">
                <!-- Aes加解密 -->
                <div v-if="item.code == 'aes'" style="margin-left: 5%;">

                    <el-radio-group style="margin-left: 42%;" v-model="aesRadio" size="small">
                        <el-radio-button label="test">测试</el-radio-button>
                        <el-radio-button label="beta">演示</el-radio-button>
                        <el-radio-button label="prod">正式</el-radio-button>
                    </el-radio-group>
                    <div style="margin-top: 1%; display: flex; justify-items: center;width: 100%;">
                        <span style="margin-top: 1%; width: 80%;">
                            <el-input style="width: 100%;" type="textarea"  v-model="aesForm.key" placeholder="请输入..."
                                @aesChange="aesChange()" :rows="encryptRows" :autosize="{ minRows: 30, maxRows: 30 }"
                                @input="aesInput" @focus="aesFocus"
                                show-word-limit
                                maxlength="10000000"></el-input>
                        </span>
                        <span style="width: 30%;">

                        </span>


                        <div style="margin-top: 1%;margin-left: 20px;width: 100%;height: 650px;overflow-y: scroll;">
                            <json-viewer :value="aesForm.value" :expand-depth="5" :copyable="copyConfig" :show-double-quotes="true" :show-array-index="false">
                            </json-viewer>

                        </div>
                    </div>


                    <span style="margin-left: 41%;">
                        <span>
                            <el-button type="danger" size="mini" :disabled="aesForm.key == ''"
                                @click="aesClick(1)">加密</el-button>
                            <el-button style="margin-left: 1%;" :disabled="aesForm.key == ''" type="success" size="mini"
                                @click="aesClick(2)">解密</el-button>
                        </span>


                    </span>

                </div>
                <!-- 图片处理 -->
                <!--
                    image/*     所有图片
                    image/png   png图片
                    image/jpg   jpg图片
                    image/gif   gir动图
                    application/msword  Word文档（.doc）
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document     Word文档（.docx）
                    application/vnd.ms-excel    Excel文档（.xls）
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet   Excel文档（.xlsx）
                    application/vnd.ms-powerpoint   PPT文档（.ppt）
                    application/vnd.openxmlformats-officedocument.presentationml.presentation   PPT文档（.pptx）
                    application/zip     压缩文件
                    text/plain  文本文件
                    text/html   HTML文件
                    text/css    css文件
                    application/pdf    pdf文件
                    audio/*     音频文件
                    video/*     视频文件
                -->

                <div v-if="item.code == 'image'">
                    <div style="width: 30%;">
                        <el-upload accept="image/*" :on-change="imageOnChange" class="upload-demo"
                            :file-list="imageForm.fileList" :auto-upload="false" drag action=""
                            :on-preview="imageHandlePreview" :on-remove="imageHandleRemove" list-type="picture" :limit="5">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" @click="imageUpload">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2MB</div>
                        </el-upload>

                    </div>

                </div>


            </el-tab-pane>

        </el-tabs>
    </div>
</template>
<script>
import commonApi from "@/api/common.js";

export default {
    data() {
        return {
            buttionArr: [],
            encryptRows: 30,
            decryptRows: 30,
            activeName: 'aes',
            aesRadio: 'test',
            aesForm: {
                key: "",
                value: {}
            },
            imageForm: {
                fileList: []
            },
            copyConfig:{copyText: "复制", copiedText: "已复制"} ,
            isAes: false,
        };
    },
    created() {
        this.getMenuButtions();
    },
    methods: {
        handleAesClick(tab) {

        },
        getMenuButtions() {
            //获取按钮权限
            const menuId = this.$route.query.meunId;
            this.$get("/user/getUserButton", { menuId: menuId }).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.buttionArr = res.data
                    console.log(this.buttionArr)
                    if (this.buttionArr.length > 0) {
                        this.activeName = this.buttionArr[0].code;
                    }
                }
            });
        },
        aesInput() {
            this.aesForm.key = this.aesForm.key.replaceAll(" ", "");
        },
        aesFocus() {
            //this.aesForm.key = "";
           // this.aesForm.value = ""
        },
        aesClick(type) {
            let params = { key: this.aesForm.key, type: type, env: this.aesRadio };
            this.isAes = true;
            this.$post(commonApi.aesOperate, params).then((res) => {
                if (res.success && res.statusCode == 200) {
                    if (type == 1) {
                        this.aesForm.value = res.data;
                    } else {
                        try {
                            this.aesForm.value = JSON.parse(res.data);
                         }
                        catch {
                            this.aesForm.value = res.data;
                      }
                       
                    }
                    console.log(this.aesForm.value)
                }
            });

        },
        aesChange() {
            this.aesForm.value = ""
            if (this.aesForm.key == "") {
                this.aesForm.value = ""
            }
        },
        imageHandleRemove(file, fileList) {
            console.log(file, fileList);
        },
        imageHandlePreview(file) {
            console.log(file);
        },
        imageOnChange(file) {
            this.imageForm.fileList = [];
            this.imageForm.fileList.push(file)
        },
        imageUpload() {

        }
    }
};
</script>
<style scoped>
.container {
    margin: 20px;
    height: 95vh;
    
}

</style>